import Ranking_viewright from "./Ranking_viewright";

import Popup from "./Popup"
function Ranking_view() {
    return (
        <>
            
            <Ranking_viewright></Ranking_viewright>
            <Popup></Popup>
            
        </>
    )
}

export default Ranking_view