import Slider from "./slider"
import Popup from "./Popup"


function Program() {
    return (
        
        <>
         
            <Slider></Slider>
            <Popup></Popup>
            
        </>
    )
}

export default Program;